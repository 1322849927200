<template>
  <div class="home">
<!--    <elMenu></elMenu>-->
    <img src="../../../../public/images/IPC/light1/download.jpg" width="100%">
    <div class="solarEnergy_box">
      <div class="solarEnergy_box_1">
        <img src="../../../../public/images/IPC/network/1.jpg" width="400px" height="400px">
      </div>
      <div class="solarEnergy_box_2">
        <h1>智能摄像灯1</h1>
        <p>品牌：维拍</p>
        <p>产品功能：智能摄像机和照明灯结合</p>
        <p>内存容量：8G 16G 32G</p>
        <p>智能类型：安卓 IOS</p>
        <p>质保年限：1年</p>
        <p>颜色：白</p>
      </div>
    </div>
    <div class="tile">详情介绍</div>
    <div class="light1_box">
      <table border="1" cellspacing="0" width="1100px">
        <tbody>
        <tr>
          <td width="93" valign="center">
            <p align="center"><strong>类别</strong><strong> </strong></p>
          </td>
          <td width="114" valign="top">
            <p><strong>类型</strong><strong> </strong></p>
          </td>
          <td width="359" valign="top">
            <p><strong>参数</strong><strong> </strong></p>
          </td>
        </tr>
        <tr>
          <td width="93" valign="center" rowspan="2">
            <p align="center"><strong>系统</strong><strong> </strong></p>
          </td>
          <td width="114" valign="top">
            <p>操作系统</p>
          </td>
          <td width="359" valign="top">
            <p>嵌入式Linux</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>在线用户数</p>
          </td>
          <td width="359" valign="top">
            <p>4个用户同时在线观看</p>
          </td>
        </tr>
        <tr>
          <td width="93" valign="center" rowspan="4">
            <p align="center"><strong>采集</strong><strong> </strong></p>
          </td>
          <td width="114" valign="top">
            <p>图像传感器</p>
          </td>
          <td width="359" valign="top">
            <p>1/2.7英寸1080p逐行扫描CMOS传感器</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>最低照度</p>
          </td>
          <td width="359" valign="top">
            <p>0.5Lux（彩色模式）,0.1Lux（黑白模式）</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>镜头/可视角度</p>
          </td>
          <td width="359" valign="top">
            <p>4.0mm@F2.0/107.7&deg;对角</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>夜视</p>
          </td>
          <td width="359" valign="top">
            <p>双滤光片自动切换，8颗贴片红外灯，照射距离10米</p>
          </td>
        </tr>
        <tr>
          <td width="93" valign="center" rowspan="4">
            <p align="center"><strong>视频</strong><strong> </strong></p>
          </td>
          <td width="114" valign="top">
            <p>压缩标准</p>
          </td>
          <td width="359" valign="top">
            <p>H.264 main profile@level:4.0/Motion-JPEG</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>三码流</p>
          </td>
          <td width="359" valign="top">
            <p>主码流：1080p（1920&times;1080）@15fps <br />
              次码流：640*360@15fps <br />
              第三码流：320*180@10fps</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>码率/最大帧率</p>
          </td>
          <td width="359" valign="top">
            <p>128～4096kbps/25fps</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>图象调整</p>
          </td>
          <td width="359" valign="top">
            <p>亮度、对比度、饱和度、色度可调</p>
          </td>
        </tr>
        <tr>
          <td width="93" valign="center" rowspan="4">
            <p align="center"><strong>音频</strong><strong> </strong></p>
          </td>
          <td width="114" valign="top">
            <p>输入</p>
          </td>
          <td width="359" valign="top">
            <p>内置－48dB麦克风</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>输出</p>
          </td>
          <td width="359" valign="top">
            <p>内置8Ω1W喇叭</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>采样频率/位宽</p>
          </td>
          <td width="359" valign="top">
            <p>8KHz/16bit</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>压缩标准/码率</p>
          </td>
          <td width="359" valign="top">
            <p>ADPCM/32kbps</p>
          </td>
        </tr>
        <tr>
          <td width="93" valign="center" rowspan="4">
            <p align="center"><strong>网络</strong><strong> </strong></p>
          </td>
          <td width="114" valign="top">
            <p>网络协议</p>
          </td>
          <td width="359" valign="top">
            <p>TCP/IP,HTTP,TCP,UDP,DHCP,DNS,NTP,RTSP,P2P等</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>无线网络</p>
          </td>
          <td width="359" valign="top">
            <p>wifi802.11 b/g/n</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>无线频率</p>
          </td>
          <td width="359" valign="top">
            <p>2.4~2.4835GHz</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>无线安全加密</p>
          </td>
          <td width="359" valign="top">
            <p>64/128-bit WEP/WPA-PSK/WPA2-PSK&nbsp;数据加密</p>
          </td>
        </tr>
        <tr>
          <td width="93" valign="center">
            <p align="center"><strong>存储</strong><strong> </strong></p>
          </td>
          <td width="114" valign="top">
            <p>存储功能</p>
          </td>
          <td width="359" valign="top">
            <p>支持T-Flash卡(最高支持128GB)</p>
          </td>
        </tr>
        <tr>
          <td width="93" valign="center">
            <p align="center"><strong>报警</strong><strong> </strong></p>
          </td>
          <td width="114" valign="top">
            <p>报警检测</p>
          </td>
          <td width="359" valign="top">
            <p>支持移动侦测</p>
          </td>
        </tr>
        <tr>
          <td width="93" valign="center" rowspan="2">
            <p align="center"><strong>物理指标</strong><strong> </strong></p>
          </td>
          <td width="114" valign="top">
            <p>输入电压</p>
          </td>
          <td width="359" valign="top">
            <p>100V~240V</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="top">
            <p>工作环境</p>
          </td>
          <td width="359" valign="top">
            <p>工作温度：-10～50℃</p>
          </td>
        </tr>
        <tr>
          <td width="93" valign="center" rowspan="2">
            <p align="center"><strong>LED灯</strong><strong> </strong></p>
          </td>
          <td width="114" valign="center">
            <p>光源</p>
          </td>
          <td width="359" valign="center">
            <p>2颗*白光LED</p>
          </td>
        </tr>
        <tr>
          <td width="114" valign="center">
            <p>流明</p>
          </td>
          <td width="359" valign="center">
            <p>300-400ML</p>
          </td>
        </tr>
        <tr>
          <td width="93" valign="center">
            <p align="center"><strong>特色功能</strong><strong> </strong></p>
          </td>
          <td width="114" valign="top">
            <p>&nbsp;</p>
          </td>
          <td width="359" valign="top">
            <p>1、灯光远程控制（开/关/亮度控制） <br />
              2、远程报警信息关闭等 <br />
              3、移动侦测报警（APP同步推送）</p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import foot from '../../../../src/components/foot'
import elMenu from "../../../components/elMenu";
import navigation from "../../../components/navigation";

export default {
  name: "light1",
  components: {
    elMenu, foot,navigation
  },
}
</script>

<style scoped>
.solarEnergy_box{
  width: 1280px;
  margin: 10px auto;
  height: 430px;
  border: 1px #ececec solid;
}
.solarEnergy_box_1{
  width: 45%;
  float: left;
  height: 400px;
  text-align: center!important;
}
.solarEnergy_box_2{
  width: 51%;
  float: left;
  height: 400px;
  border-left: 1px solid #ececec;
  padding-left: 25px;
  margin-top: 15px;
}
.tile{
  text-align: center;
  font-size: 24px;
  margin: 70px;
}
table{
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ececec;
  margin: 0px auto;
}
.light1_box{
  height: 1700px;
}

</style>